import React from "react"
import PropTypes from "prop-types"

const ArrowRight = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22px"
      height="18px"
      viewBox="0 0 22 18"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M18.874 8.99998L15.849 5.97498C15.7537 5.89211 15.6765 5.79043 15.6223 5.67633C15.5682 5.56222 15.5381 5.43816 15.5342 5.31191C15.5302 5.18566 15.5524 5.05995 15.5993 4.94268C15.6462 4.8254 15.7169 4.71909 15.8068 4.63041C15.8968 4.54173 16.0041 4.4726 16.122 4.42735C16.2399 4.3821 16.3659 4.36171 16.4921 4.36746C16.6183 4.37321 16.7419 4.40497 16.8553 4.46076C16.9686 4.51655 17.0692 4.59515 17.1507 4.69165L20.8173 8.35831C20.9853 8.52966 21.0794 8.76004 21.0794 8.99998C21.0794 9.23992 20.9853 9.47029 20.8173 9.64164L17.1507 13.3083C16.9764 13.4598 16.7513 13.54 16.5205 13.5327C16.2897 13.5255 16.0701 13.4314 15.9057 13.2693C15.7413 13.1071 15.6441 12.8889 15.6336 12.6582C15.623 12.4275 15.7 12.2014 15.849 12.025L18.874 8.99998ZM3.1165 8.99998L6.1415 12.025C6.29052 12.2014 6.36746 12.4275 6.35695 12.6582C6.34644 12.8889 6.24925 13.1071 6.0848 13.2693C5.92036 13.4314 5.70078 13.5255 5.46997 13.5327C5.23916 13.54 5.01411 13.4598 4.83984 13.3083L1.17317 9.64164C1.00521 9.47029 0.911133 9.23992 0.911133 8.99998C0.911133 8.76004 1.00521 8.52966 1.17317 8.35831L4.83984 4.69165C5.01411 4.54014 5.23916 4.46 5.46997 4.46724C5.70078 4.47447 5.92036 4.56856 6.0848 4.73069C6.24925 4.89282 6.34644 5.11104 6.35695 5.34172C6.36746 5.57241 6.29052 5.79857 6.1415 5.97498L3.1165 8.99998ZM10.0465 16.5533C9.98072 16.781 9.82933 16.9743 9.6241 17.0928C9.41886 17.2112 9.17573 17.2456 8.9457 17.1887C8.71566 17.1318 8.51661 16.988 8.39029 16.7875C8.26397 16.587 8.22021 16.3454 8.26817 16.1133L11.9348 1.44664C11.9598 1.32583 12.0089 1.2113 12.0792 1.10992C12.1495 1.00853 12.2395 0.922375 12.3439 0.856613C12.4483 0.79085 12.5648 0.746833 12.6866 0.727199C12.8084 0.707565 12.9329 0.712717 13.0527 0.742348C13.1724 0.771978 13.285 0.825476 13.3836 0.899638C13.4822 0.9738 13.5648 1.0671 13.6264 1.17395C13.6881 1.28079 13.7276 1.39899 13.7425 1.52145C13.7574 1.64391 13.7474 1.76813 13.7132 1.88664L10.0465 16.5533Z" fill={color}/>
    </svg>
  )
}

ArrowRight.propTypes = {
  color: PropTypes.string,
}

ArrowRight.defaultProps = {
  color: "#000000",
}

export default ArrowRight
