import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Code from "components/icons/code"
import StyledBtn from "styles/button"

const StyledCard = styled.div`
  background: white;
  border: 1px solid #DFE7EF;
  border-top: 0.3125rem solid ${props =>props.color};
  border-radius: 0.625rem;
  text-align: left;
  max-width: 36.56rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2.5rem;
  .left {
    background-image: url(${props => props.image});
    background-size: cover;
    height: 15rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      color: ${({ theme }) => theme.colors.zodiacLight};
    }
    padding: 1.25rem;
    .text {
      margin-bottom: 1rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 15rem auto;
    margin-bottom: 0;
    height: 21rem;
    width: 36.56rem;
    max-width: 36.56rem;
    .left {
      border-bottom-left-radius: 0.625rem;
      height: unset;
    }
  }
`

const StyledTitle = styled.h4`
  text-transform: capitalize;
`

const StyledButton = styled(StyledBtn)`
  width: 100%;
  padding: 1rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  background: ${({ theme }) => theme.colors.background};
  border-radius: 0.375rem;
  margin-bottom: 0.7rem;
`

const StyledButtonWhite = styled(StyledButton)`
  background: white;
  border: 1px solid #C6D7EA;
`

const PrimaryCard = ({ color, image, title, subtitle, link, codeUrl }) => (
  <StyledCard color={color} image={image}>
    <div className="left"></div>
    <div className="right">
      <div>
        <StyledTitle className="mb-1">{title}</StyledTitle>
        <div className="text">{subtitle}</div>
      </div>
      <StyledButton to={link}>
        <span>Try it out</span><FontAwesomeIcon className="icon-right" icon={faArrowRight} />
      </StyledButton>
      {codeUrl && (
        <StyledButtonWhite to={codeUrl} target="_blank" rel="noreferrer">
          <span>View source code</span><Code color="#6485A8" />
        </StyledButtonWhite>
      )}
    </div>
  </StyledCard>
)


export default PrimaryCard
