import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import StyledBtn from "styles/button"

const StyledCardWrapper = styled.div`
  padding: 0.875rem;
`

const StyledCard = styled.div`
  padding: 2rem 1.56rem;
  border-radius: 0.625rem;
  border-top: 0.3125rem solid ${props =>props.color};
  background: ${({ theme }) => theme.colors.gradient};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  flex-direction: column;
  align-item: flex-start;
  text-align: left;
  .logo {
    width: 3.75rem;
    height: 3.75rem;
    min-height: 3.75rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props =>props.color};
    img {
      height: 2rem;
      width: auto;
    }
  }
  h4 {
    color: white;
  }
  .text {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1.5625rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 23.9375rem;
  }
`

const StyledButton = styled(StyledBtn)`
  width: 100%;
  padding: 1rem 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.zodiacLight};
  border-radius: 0.375rem;
`

const Card = ({ color, icon, title, description, link }) => (
  <StyledCardWrapper>
    <StyledCard color={color}>
      <div className="logo mb-1"><img src={icon} alt="title" /></div>
      <h4 className="mb-1">{title}</h4>
      <div className="text">{description}</div>
      <StyledButton
        to={link}
      >
        <span>Discover  demos</span><FontAwesomeIcon className="icon-right" icon={faArrowRight} />
      </StyledButton>
    </StyledCard>
  </StyledCardWrapper>
)

Card.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Card
