import React from "react"
import PropTypes from "prop-types"

const ArrowRight = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="21px"
      height="17px"
      viewBox="0 0 21 17"
    >
     <path xmlns="http://www.w3.org/2000/svg" d="M17.3727 9.84869H1.96419C1.70206 9.84869 1.45067 9.74456 1.26531 9.55921C1.07996 9.37385 0.97583 9.12246 0.97583 8.86033C0.97583 8.5982 1.07996 8.34681 1.26531 8.16146C1.45067 7.97611 1.70206 7.87197 1.96419 7.87197H17.3727L12.1344 2.63368C12.0316 2.54433 11.9484 2.4347 11.89 2.31168C11.8316 2.18865 11.7992 2.05488 11.7949 1.91875C11.7907 1.78263 11.8146 1.64709 11.8652 1.52065C11.9158 1.3942 11.9919 1.27957 12.0889 1.18396C12.1859 1.08834 12.3016 1.0138 12.4287 0.965013C12.5559 0.916224 12.6918 0.894239 12.8278 0.900439C12.9639 0.906638 13.0972 0.940887 13.2193 1.00104C13.3415 1.06119 13.45 1.14594 13.5378 1.24998L20.4563 8.16848C20.6374 8.35323 20.7389 8.60163 20.7389 8.86033C20.7389 9.11904 20.6374 9.36743 20.4563 9.55218L13.5378 16.4707C13.3499 16.634 13.1073 16.7204 12.8584 16.7126C12.6096 16.7048 12.3728 16.6034 12.1955 16.4286C12.0182 16.2538 11.9134 16.0185 11.9021 15.7698C11.8907 15.521 11.9737 15.2772 12.1344 15.087L17.3727 9.84869Z" fill={color}/>
    </svg>
  )
}

ArrowRight.propTypes = {
  color: PropTypes.string,
}

ArrowRight.defaultProps = {
  color: "#000000",
}

export default ArrowRight
